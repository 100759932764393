import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import FoundOreo from "./Popup/FoundOreo";
import { NotFoundData } from "./Popup/NotFoundData";
import NotFoundOreo from "./Popup/NotFoundOreo";

import { ServerTime } from "./Utility";

const VRScene = (props) => {
  const [serverStop, setServerStop] = useState();
 

  const isFound = () => {
    props.setIsNotFound(false);
    props.pause();
  };

  const IsFoundNot = () => {
    props.setNotOreo(false);
    // props.pause();
  };

  const number = Math.floor(Math.random() * NotFoundData.length);
  const [response, setResponse] = useState();
  const [serverStart, setServerStart] = useState();
  // const [IsSleep, setIsSleep] = useState(false);
  const location = useLocation();
  const [utmSource] = useState(
    new URLSearchParams(location?.search).get("utm_source")
  );
  const [utmMedium] = useState(
    new URLSearchParams(location?.search).get("utm_medium")
  );
  const [utm_val] = useState(location?.search);

  useEffect(() => {
    props.setYalah(false);
    const getServerTime = ServerTime;
    setServerStart(getServerTime);

    if (!props.Yalah) {
      props.run();
    }
    return () => {
      // props.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.Yalah]);

  useEffect(() => {
    switch (props.statusGame) {
      case 1:
        setResponse(props.steps[0](isFound, IsFoundNot));
        break;
      case 2:
        setResponse(props.steps[1](isFound, IsFoundNot));
        break;
      case 3:
        setResponse(props.steps[2](isFound, IsFoundNot));
        break;
      case 4:
        setResponse(props.steps[3](isFound, IsFoundNot));
        break;
      default:
        setResponse("error");
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.statusGame]);

  return (
    <div>
      
        
          <div id="MAINWRAPPER">
            <div id="WRAP">
              <div className="frame">{response}</div>
            </div>
          </div>
          <FoundOreo
              step={props.statusGame}
              desactivate={props.setIsNotFound}
              replay={props.resume}
              change={props.changeStatus}
              time= {props.timeer}
            />

          {/* {!props.isNotFound ? (
            <FoundOreo
              step={props.statusGame}
              desactivate={props.setIsNotFound}
              replay={props.resume}
              change={props.changeStatus}
              time= {props.timeer}
            />
          ) : null}
          {!props.notOreo ? (
            <NotFoundOreo
              number={number}
              desactivate={props.setNotOreo}
              replay={props.resume}
            />
          ) : null}  */}

          <div style={{ display: "none" }}>
            <Link
              to={{
                pathname: "/thanks",
                time: props.timeer,
                utmMedium: utmMedium,
                utmSource: utmSource,
                utm_val: utm_val,
                isDev: props.isDev,
                serverStart: serverStart,
              }}
              id="passtothanks"
            />
          </div>
        
     
    </div>
  );
};

export default VRScene;
