import React, { useState } from "react";
import { Link } from "react-router-dom";
import pdf from "../assets/takanon.pdf";
import logo from "../assets/img/findoreo@2x.png";
import Img from "../assets/img/img.png";
import "../assets/css/TakanonPage.css";
import GamePage from "./GamePage";
import TagManager from "react-gtm-module";

export const GoalsPage = (props) => {
  if (props.isStart) {
    return (
      <GamePage
        VR360={props.VR360}
        steps={props.steps}
        setIsNotYalla={props.setIsNotYalla}
        isNotYalla={props.isNotYalla}
        timer={props.timer}
        handleStart={props.handleStart}
        handlePause={props.handlePause}
        handleResume={props.handleResume}
        handleReset={props.handleReset}
        isDev={props.isDev}
      />
    );
  }
  return (
    <div className="ac-takanon-page">
      <div className="ac-container">
        {/* <div className="bread">
          <Link
            to="/"
            onClick={() => {
              window.location.replace("https://testmilkaandoreo.activated.co.il/");
              TagManager.dataLayer({ dataLayer: { event: "homepage" } });
            }}
          >
            בית{" "}
          </Link>{" "}
          {" > "}
          <Link
            to="/SharePage"
            onClick={() => {
              TagManager.dataLayer({ dataLayer: { event: "true_friend" } });
            }}
          >
            חבר אמיתי משתף
          </Link>
        </div> */}
        <div className="ac-img-wrapper">
          <img src={logo} alt="Logo" className="ac-img-responive" />
        </div>

        {/* <span class="ac-sharepage-text">
          בעוגיית אוראו לא משתפים חברים,
          <br />
          <strong>
            במשחק אוראו שאפשר
            <br />
            לזכות בו במיני קופר <br />
            משתפים במקסימום
          </strong>
        </span> */}
        <span className="ac-takanon-text">
          מוצאים ת׳אוראו במולטיוורס
          <br />
          ויכולים לזכות במיני קופר!
        </span>

        <a
          href={pdf}
          onClick={() => {
            // window.location.replace("https://testmilkaandoreo.activated.co.il/");
            TagManager.dataLayer({ dataLayer: { event: "Takanon" } });
          }}
          target="_blank"
          className="ac-takaon-link"
        >
          להורדת התקנון
        </a>
        <div className="ac-img-wrapper ac-img-wrapper-2">
          <img src={Img} alt="illustration" className="ac-img-responive" />
        </div>
      </div>
    </div>
  );
};

export default GoalsPage;
