import React, { useState } from "react";
import logo from "../assets/img/football/logo.webp";
import text_bg from "../assets/img/football/1.webp";
import chempions from "../assets/img/football/2.webp";
import image360 from "../assets/img/football/360.webp";
import image6546 from "../assets/img/football/6546.webp";

import "../assets/css/OpeningPage.css";
import GamePage from "./GamePage";
import TagManager from "react-gtm-module";

const OpeningPage = (props) => {
  const [containerState, setContainerState] = useState(1);
  const handleFirstButtonClick = () => {
    setContainerState(2);
    TagManager.dataLayer({ dataLayer: { event: "go_play" } });
  };
  const handleSecondButtonClick = () => {
    setContainerState(3);
  };
  if (props.isStart) {
    return (
      <GamePage
        VR360={props.VR360}
        steps={props.steps}
        setIsNotYalla={props.setIsNotYalla}
        isNotYalla={props.isNotYalla}
        timer={props.timer}
        handleStart={props.handleStart}
        handlePause={props.handlePause}
        handleResume={props.handleResume}
        handleReset={props.handleReset}
        isDev={props.isDev}
      />
    );
  }
  return (
    <div
      className="ac-openning-page"
      // style={{
      //   backgroundImage: `url(${
      //     process.env.PUBLIC_URL + "/img/startgameBg.jpg"
      //   })`,
      //   backgroundPosition: "center",
      //   backgroundRepeat: "noRepeat",
      //   backgroundSize: "100%",
      // }}
    >
      {containerState === 1 && (
        <div className="ac-container container-1">
          <div className="ac-img-wrapper">
            <img src={logo} alt="הרכב מנצח" className="ac-img-responive" />
          </div>
          <div className="ac-text-box ac-text-box-1">
            <img src={text_bg} alt="" className="ac-img-responive ac-text-bg-box" />
            <span class="ac-text ac-text-m ac-text-m-1">ברוכים הבאים לפעילות!</span>
            <br/> 

            <span class="ac-text ac-text-m ac-text-m-2">
            מצאו את שחקני הנבחרת<br/>
            של מילקה ואוראו ותוכלו לזכות<br/> 
            בכרטיס זוגי לגמר היורו 2024<br/> 
            שיתקיים בברלין + טיסה ומלון.
            </span>
            <br/><br/> 
            <span class="ac-text ac-text-m ac-text-m-3">
            על מנת לזכות, יש להציג חשבונית על<br/>
            קניית מוצרי אוראו ומילקה בסך 30 ₪
            </span>
            <div onClick={handleFirstButtonClick } className="ac-btn ac-start-game-btn" role="button">
              <div className="ac-inner-btn">
                <span>כנסו למשחק</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="15.75" height="18.195" viewBox="0 0 15.75 18.195">
                  <g id="Group_11" data-name="Group 11" transform="translate(-136 -608.792)">
                    <g id="ctrl-left" transform="translate(134.504 608.395)">
                      <g id="Group_10" data-name="Group 10" transform="translate(8.496 0.397)">
                        <path id="Path_7" data-name="Path 7" d="M17.244,1.717a.292.292,0,0,0-.111-.194L15.743.457a.292.292,0,0,0-.408.054L8.557,9.319a.292.292,0,0,0,0,.356l6.775,8.8a.292.292,0,0,0,.408.054l1.387-1.068a.292.292,0,0,0,.054-.408L11.366,9.494l5.819-7.562A.292.292,0,0,0,17.244,1.717Z" transform="translate(-8.496 -0.397)" fill="#fff"/>
                      </g>
                    </g>
                    <g id="ctrl-left-2" data-name="ctrl-left" transform="translate(127.504 608.395)">
                      <g id="Group_10-2" data-name="Group 10" transform="translate(8.496 0.397)">
                        <path id="Path_7-2" data-name="Path 7" d="M17.244,1.717a.292.292,0,0,0-.111-.194L15.743.457a.292.292,0,0,0-.408.054L8.557,9.319a.292.292,0,0,0,0,.356l6.775,8.8a.292.292,0,0,0,.408.054l1.387-1.068a.292.292,0,0,0,.054-.408L11.366,9.494l5.819-7.562A.292.292,0,0,0,17.244,1.717Z" transform="translate(-8.496 -0.397)" fill="#fff"/>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          
          </div>
        </div>
      )}
      {containerState === 2 && (
        <div className="container container-2">
          <div className="ac-img-wrapper">
            <img src={logo} alt="הרכב מנצח" className="ac-img-responive ac-herkev" />
          </div>
          <div className="ac-text-box ac-text-box-2">
            <span class="ac-text ac-text-m ac-text-m-4">הכירו את נבחרת המנצחת של אוראו<br/> מילקה שמסתתרת ביציעים</span>
          </div>
          <img src={chempions} alt="" className="ac-img-responive ac-chempions" />
          <div onClick={handleSecondButtonClick} className="ac-btn ac-start-game-btn"  role="button">
            <div className="ac-inner-btn">
              <span>המשך</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="15.75" height="18.195" viewBox="0 0 15.75 18.195">
                <g id="Group_11" data-name="Group 11" transform="translate(-136 -608.792)">
                  <g id="ctrl-left" transform="translate(134.504 608.395)">
                    <g id="Group_10" data-name="Group 10" transform="translate(8.496 0.397)">
                      <path id="Path_7" data-name="Path 7" d="M17.244,1.717a.292.292,0,0,0-.111-.194L15.743.457a.292.292,0,0,0-.408.054L8.557,9.319a.292.292,0,0,0,0,.356l6.775,8.8a.292.292,0,0,0,.408.054l1.387-1.068a.292.292,0,0,0,.054-.408L11.366,9.494l5.819-7.562A.292.292,0,0,0,17.244,1.717Z" transform="translate(-8.496 -0.397)" fill="#fff"/>
                    </g>
                  </g>
                  <g id="ctrl-left-2" data-name="ctrl-left" transform="translate(127.504 608.395)">
                    <g id="Group_10-2" data-name="Group 10" transform="translate(8.496 0.397)">
                      <path id="Path_7-2" data-name="Path 7" d="M17.244,1.717a.292.292,0,0,0-.111-.194L15.743.457a.292.292,0,0,0-.408.054L8.557,9.319a.292.292,0,0,0,0,.356l6.775,8.8a.292.292,0,0,0,.408.054l1.387-1.068a.292.292,0,0,0,.054-.408L11.366,9.494l5.819-7.562A.292.292,0,0,0,17.244,1.717Z" transform="translate(-8.496 -0.397)" fill="#fff"/>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </div>
      )}
      {containerState === 3 && (
        <div className="container container-3">
          <img src={image6546} alt="" className="ac-img-responive ac-image6546" />
          <div className="ac-overlay"></div>
          <div className="ac-popup-start-game">
            <div className="ac-text-box">
              <span class="ac-text ac-text-r ac-text-r-1">ביציע מסתתרים 3 שחקני נבחרת.</span>
              <span class="ac-text ac-text-b ac-text-b-1">על מנת לזוז ביציע יש להזיז<br/> את הנייד לכל הכיוונים</span>
            </div>
            
            <img src={image360} alt="" className="ac-img-responive ac-image360" />
            <div onClick={() => {props.setIsStart(true);}} className="ac-btn ac-start-game-btn"  role="button">
              <div className="ac-inner-btn">
                <span>למשחק</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="15.75" height="18.195" viewBox="0 0 15.75 18.195">
                  <g id="Group_11" data-name="Group 11" transform="translate(-136 -608.792)">
                    <g id="ctrl-left" transform="translate(134.504 608.395)">
                      <g id="Group_10" data-name="Group 10" transform="translate(8.496 0.397)">
                        <path id="Path_7" data-name="Path 7" d="M17.244,1.717a.292.292,0,0,0-.111-.194L15.743.457a.292.292,0,0,0-.408.054L8.557,9.319a.292.292,0,0,0,0,.356l6.775,8.8a.292.292,0,0,0,.408.054l1.387-1.068a.292.292,0,0,0,.054-.408L11.366,9.494l5.819-7.562A.292.292,0,0,0,17.244,1.717Z" transform="translate(-8.496 -0.397)" fill="#fff"/>
                      </g>
                    </g>
                    <g id="ctrl-left-2" data-name="ctrl-left" transform="translate(127.504 608.395)">
                      <g id="Group_10-2" data-name="Group 10" transform="translate(8.496 0.397)">
                        <path id="Path_7-2" data-name="Path 7" d="M17.244,1.717a.292.292,0,0,0-.111-.194L15.743.457a.292.292,0,0,0-.408.054L8.557,9.319a.292.292,0,0,0,0,.356l6.775,8.8a.292.292,0,0,0,.408.054l1.387-1.068a.292.292,0,0,0,.054-.408L11.366,9.494l5.819-7.562A.292.292,0,0,0,17.244,1.717Z" transform="translate(-8.496 -0.397)" fill="#fff"/>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </div>
          
        </div>
      )}
    </div>
  );
};

export default OpeningPage;
