import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import PopupEnd from "../PopupEnd";
import TagManager from "react-gtm-module";
import CircleImg from "../../assets/img/popup-circle.png";

function convertTimeFormat(time) {
  if (!time) return '';

  // Split the time string into an array using ':' as the separator
  const timeParts = time.split(':');

  // Extract minutes and seconds
  const minutes = timeParts[0];
  const seconds = timeParts[1];

  // Return the formatted time as MM:SS
  return `${minutes}:${seconds}`;
}

const FoundOreo = (props) => {
  const navigate = useNavigate();
  const clickEvent = () => {
    props.desactivate(true);
    props.replay();
    // console.log(props.step);
    console.log(props.time);
    switch (props.step) {
      case 2:
        TagManager.dataLayer({ dataLayer: { event: "level_1" } });
        break;
      case 3:
        TagManager.dataLayer({ dataLayer: { event: "level_2" } });
        break;
      case 4:
        TagManager.dataLayer({ dataLayer: { event: "level_3" } });
        break;
      default:
        break;
    }
  };

  const handleClick = () => {
    navigate('/FormPage');
  };

  let $time =convertTimeFormat(props.time);


  useState(() => {
    if (props.step === 4) {
      let el = document.getElementById("passtothanks");
      if (el) {
        // TagManager.dataLayer({ dataLayer: { event: "croatia" } });
        el.click();
      }
    } else {
      props.change();
    }
  }, [props.step]);



  // if (props.step === 3) {
  //   return (
  //     // After Second Step
  //     <PopupEnd id="ac-found-oreo" events={clickEvent}>
  //       <img
  //         src={CircleImg}
  //         alt=""
  //         className="ac-img-responsive ac-popup-circle"
  //       />
  //       <span class="ac-popuptitle">
  //         בום! <br />
  //         מצאת ת׳אוראו
  //       </span>
  //       <p>נשארו רק עוד {5 - props.step} יקומים...</p>

  //       <Link to="" onClick={clickEvent} className="ac-btn ac-btn-goto-form">
  //         <div className="ac-inner-btn">
  //           <span>ליקום הבא</span>
  //           <svg
  //             xmlns="http://www.w3.org/2000/svg"
  //             width="21.167"
  //             height="21.168"
  //             viewBox="0 0 21.167 21.168"
  //           >
  //             <g data-name="Group 32" transform="translate(0 10.584)">
  //               <path
  //                 fill="#0057c8"
  //                 d="M0 0l21.167 10.584v-21.168z"
  //                 data-name="Path 17"
  //               ></path>
  //             </g>
  //           </svg>
  //         </div>
  //       </Link>
  //     </PopupEnd>
  //   );
  // }
  if (props.step === 4) {
    return (
      
      
     

      // After First Step
      <PopupEnd id="ac-found-oreo" events={clickEvent}>
      <div class="ac-text-box">
        <span class="ac-popuptitle">כל הכבוד</span>
        <span class="ac-text ac-text-r ac-text-r-1">מצאת את שחקני נבחרת<br/> מילקה אוראו.</span>
        <br/>
        <span class="ac-text ac-text-b ac-text-b-1">ביציע הבא מסתתרים 3 שחקנים</span>
      </div>
    
      {/* <p> נשארו לך עוד {5 - props.step} יקומים</p> */}

      <Link to="" onClick={clickEvent} className="ac-btn ac-btn-goto-form">
        <div className="ac-inner-btn">
          <span>לשלב הבא</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="15.75" height="18.195" viewBox="0 0 15.75 18.195">
                <g id="Group_11" data-name="Group 11" transform="translate(-136 -608.792)">
                  <g id="ctrl-left" transform="translate(134.504 608.395)">
                    <g id="Group_10" data-name="Group 10" transform="translate(8.496 0.397)">
                      <path id="Path_7" data-name="Path 7" d="M17.244,1.717a.292.292,0,0,0-.111-.194L15.743.457a.292.292,0,0,0-.408.054L8.557,9.319a.292.292,0,0,0,0,.356l6.775,8.8a.292.292,0,0,0,.408.054l1.387-1.068a.292.292,0,0,0,.054-.408L11.366,9.494l5.819-7.562A.292.292,0,0,0,17.244,1.717Z" transform="translate(-8.496 -0.397)" fill="#fff"/>
                    </g>
                  </g>
                  <g id="ctrl-left-2" data-name="ctrl-left" transform="translate(127.504 608.395)">
                    <g id="Group_10-2" data-name="Group 10" transform="translate(8.496 0.397)">
                      <path id="Path_7-2" data-name="Path 7" d="M17.244,1.717a.292.292,0,0,0-.111-.194L15.743.457a.292.292,0,0,0-.408.054L8.557,9.319a.292.292,0,0,0,0,.356l6.775,8.8a.292.292,0,0,0,.408.054l1.387-1.068a.292.292,0,0,0,.054-.408L11.366,9.494l5.819-7.562A.292.292,0,0,0,17.244,1.717Z" transform="translate(-8.496 -0.397)" fill="#fff"/>
                    </g>
                  </g>
                </g>
          </svg>
        </div>
      </Link>
    </PopupEnd>
    );
  } else if (props.step !== 4) {
    return (
      // After Third Step
      <PopupEnd id="ac-found-oreo" events={clickEvent}>
      <div class="ac-text-box">
        <span className="ac-popuptitle">עשית זאת!</span>
        <br/>
        <span className="ac-text ac-text-b ac-text-b-1">מצאת את כל 11 שחקני הנבחרת<br/> המנצחת של מילקה אוראו בזמן-</span>
      </div>
      <span className="ac-your-time">{$time}</span>

      {/* <Link to="" onClick={clickEvent} className="ac-btn ac-btn-goto-form">
        <div className="ac-inner-btn">
          <span>הרשמה לתחרות</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="15.75" height="18.195" viewBox="0 0 15.75 18.195">
                <g id="Group_11" data-name="Group 11" transform="translate(-136 -608.792)">
                  <g id="ctrl-left" transform="translate(134.504 608.395)">
                    <g id="Group_10" data-name="Group 10" transform="translate(8.496 0.397)">
                      <path id="Path_7" data-name="Path 7" d="M17.244,1.717a.292.292,0,0,0-.111-.194L15.743.457a.292.292,0,0,0-.408.054L8.557,9.319a.292.292,0,0,0,0,.356l6.775,8.8a.292.292,0,0,0,.408.054l1.387-1.068a.292.292,0,0,0,.054-.408L11.366,9.494l5.819-7.562A.292.292,0,0,0,17.244,1.717Z" transform="translate(-8.496 -0.397)" fill="#fff"/>
                    </g>
                  </g>
                  <g id="ctrl-left-2" data-name="ctrl-left" transform="translate(127.504 608.395)">
                    <g id="Group_10-2" data-name="Group 10" transform="translate(8.496 0.397)">
                      <path id="Path_7-2" data-name="Path 7" d="M17.244,1.717a.292.292,0,0,0-.111-.194L15.743.457a.292.292,0,0,0-.408.054L8.557,9.319a.292.292,0,0,0,0,.356l6.775,8.8a.292.292,0,0,0,.408.054l1.387-1.068a.292.292,0,0,0,.054-.408L11.366,9.494l5.819-7.562A.292.292,0,0,0,17.244,1.717Z" transform="translate(-8.496 -0.397)" fill="#fff"/>
                    </g>
                  </g>
                </g>
          </svg>
        </div>
      </Link> */}
      <button onClick={handleClick} className="ac-btn ac-btn-goto-form">
        <div className="ac-inner-btn">
          <span>הרשמה לתחרות</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="15.75" height="18.195" viewBox="0 0 15.75 18.195">
                <g id="Group_11" data-name="Group 11" transform="translate(-136 -608.792)">
                  <g id="ctrl-left" transform="translate(134.504 608.395)">
                    <g id="Group_10" data-name="Group 10" transform="translate(8.496 0.397)">
                      <path id="Path_7" data-name="Path 7" d="M17.244,1.717a.292.292,0,0,0-.111-.194L15.743.457a.292.292,0,0,0-.408.054L8.557,9.319a.292.292,0,0,0,0,.356l6.775,8.8a.292.292,0,0,0,.408.054l1.387-1.068a.292.292,0,0,0,.054-.408L11.366,9.494l5.819-7.562A.292.292,0,0,0,17.244,1.717Z" transform="translate(-8.496 -0.397)" fill="#fff"/>
                    </g>
                  </g>
                  <g id="ctrl-left-2" data-name="ctrl-left" transform="translate(127.504 608.395)">
                    <g id="Group_10-2" data-name="Group 10" transform="translate(8.496 0.397)">
                      <path id="Path_7-2" data-name="Path 7" d="M17.244,1.717a.292.292,0,0,0-.111-.194L15.743.457a.292.292,0,0,0-.408.054L8.557,9.319a.292.292,0,0,0,0,.356l6.775,8.8a.292.292,0,0,0,.408.054l1.387-1.068a.292.292,0,0,0,.054-.408L11.366,9.494l5.819-7.562A.292.292,0,0,0,17.244,1.717Z" transform="translate(-8.496 -0.397)" fill="#fff"/>
                    </g>
                  </g>
                </g>
          </svg>
        </div>
      </button>
    </PopupEnd>
    );
  }
};

export default FoundOreo;
