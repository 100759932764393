import React, { useState, useEffect } from 'react';
import loader_bg from "../assets/img/football/loader_bg.webp";
import img6546 from "../assets/img/football/6546.webp";

function Countdown({ runCountdown, onCountdownEnd }) {
  const [count, setCount] = useState(3);

  useEffect(() => {
    if (runCountdown && count > 0) {
      const timer = setTimeout(() => {
        setCount(count - 1);
      }, 1000);
      return () => clearTimeout(timer); // Clean up the timer on unmount
    } else if (count === 0) {
      onCountdownEnd();
    }
  }, [count, runCountdown, onCountdownEnd]);

  return (
    <div className='ac-countdow'>
      <img src={img6546} alt="" className="ac-img-responive ac-countdow-bg" />
      <div className='ac-overlay-countdow'></div>
      {runCountdown && count > 0 ? <h1>{count}</h1> : null}
    </div>
  );
}

export default Countdown;
