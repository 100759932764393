import React, { useState } from 'react';
import TagManager from "react-gtm-module";

const FormFinal = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [nickname, setNickname] = useState('');
  const [image, setImage] = useState(null);
  const [checked, setChecked] = useState(false);
  const [checkedTakanon, setCheckedTakanon] = useState(false);
  const [message, setMessage] = useState('');


  useState(() => {
    
    const header = document.getElementsByTagName('header')[0];
    header.style.display = 'none';
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (checked && checkedTakanon) {
      try {
        let res = await fetch(
          "https://crud.activated.digital/app/app/f?id=13",
          {
            method: "POST",
            timeout: 0,
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify({ name, email, nickname, image }),
          }
        );
        let resJson = await res.json();
        if (res.status === 200) {
          TagManager.dataLayer({
            dataLayer: { event: "form_submitt" },
          });
          setName("");
          setEmail("");
          setNickname("");
          setImage(null);
          setMessage("User created successfully");
          // setAddImgPopup(true); // Uncomment if you have a popup handling
        } else {
          setMessage("Some error occurred");
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      alert("אנה סמן שני צ׳קבוקסים");
    }
  };

  const handleImageChange = (event) => {
    setImage(event.target.files[0]);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='ac-input'>
        <label class="ac-hide">שם:</label>
        <input 
          type="text" 
          value={name} 
          placeholder="שם"
          onChange={(e) => setName(e.target.value)} 
        />
      </div>
      <div className='ac-input'>
        <label class="ac-hide">דוא"ל:</label>
        <input 
          type="email" 
          value={email} 
          placeholder='דוא"ל'
          onChange={(e) => setEmail(e.target.value)} 
        />
      </div>
      <div className='ac-input'>
        <label class="ac-hide">נייד:</label>
        <input 
          type="text" 
          value={nickname} 
          placeholder="נייד"
          onChange={(e) => setNickname(e.target.value)} 
        />
      </div>
      <div className='ac-input'>
        {/* <label>צילום חשבונית</label>
        <input 
          type="file" 
          onChange={handleImageChange} 
        /> */}
          <input 
          id="upload-button" 
          type="file" 
          accept="image/*" 
          style={{ display: 'none' }} 
          onChange={handleImageChange} 
        />
        <label htmlFor="upload-button">
          <span className='ac-icon-upload-image'>
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width={26.55}
              height={24.195}
             
            >
              <g
                fill="none"
                stroke="#fff"
                strokeLinecap="square"
                strokeWidth={3}
                data-name="Group 32"
                transform="translate(1.5 1.5)"
              >
                <circle
                  cx={4.317}
                  cy={4.317}
                  r={4.317}
                  data-name="Ellipse 3"
                  transform="translate(7.457 7.85)"
                />
                <path
                  d="M21.195 21.195H2.355A2.355 2.355 0 0 1 0 18.84V6.28a2.355 2.355 0 0 1 2.355-2.355h3.532L8.242 0h7.065l2.355 3.925h3.532A2.355 2.355 0 0 1 23.55 6.28v12.56a2.355 2.355 0 0 1-2.355 2.355Z"
                  data-name="Path 18"
                />
              </g>
            </svg>
            </span> צילום חשבונית
        </label>
      </div>
      <button type="button" className='ac-no-image'>ואם אין לי חשבונית כרגע?</button>
      <button type="submit" className='send'>
        <span>שליחה</span>
        <span className='ac-arrows'>
          <svg xmlns="http://www.w3.org/2000/svg" width="15.75" height="18.195" viewBox="0 0 15.75 18.195">
                  <g id="Group_11" data-name="Group 11" transform="translate(-136 -608.792)">
                    <g id="ctrl-left" transform="translate(134.504 608.395)">
                      <g id="Group_10" data-name="Group 10" transform="translate(8.496 0.397)">
                        <path id="Path_7" data-name="Path 7" d="M17.244,1.717a.292.292,0,0,0-.111-.194L15.743.457a.292.292,0,0,0-.408.054L8.557,9.319a.292.292,0,0,0,0,.356l6.775,8.8a.292.292,0,0,0,.408.054l1.387-1.068a.292.292,0,0,0,.054-.408L11.366,9.494l5.819-7.562A.292.292,0,0,0,17.244,1.717Z" transform="translate(-8.496 -0.397)" fill="#fff"/>
                      </g>
                    </g>
                    <g id="ctrl-left-2" data-name="ctrl-left" transform="translate(127.504 608.395)">
                      <g id="Group_10-2" data-name="Group 10" transform="translate(8.496 0.397)">
                        <path id="Path_7-2" data-name="Path 7" d="M17.244,1.717a.292.292,0,0,0-.111-.194L15.743.457a.292.292,0,0,0-.408.054L8.557,9.319a.292.292,0,0,0,0,.356l6.775,8.8a.292.292,0,0,0,.408.054l1.387-1.068a.292.292,0,0,0,.054-.408L11.366,9.494l5.819-7.562A.292.292,0,0,0,17.244,1.717Z" transform="translate(-8.496 -0.397)" fill="#fff"/>
                      </g>
                    </g>
                  </g>
          </svg>
        </span>
      </button>
      <div className='ac-checkbox'>
        <label>
          <input 
            type="checkbox" 
            checked={checked} 
            onChange={(e) => setChecked(e.target.checked)} 
          />
       <span>ידוע לי שכדי לזכות, צריך לצלם חשבונית על שני מוצרי מילקה (שוקולד ו/או עוגיות)</span>
        </label>
      </div>
      <div className='ac-checkbox'>
        <label>
          <input 
            type="checkbox" 
            checked={checkedTakanon} 
            onChange={(e) => setCheckedTakanon(e.target.checked)} 
          />
            <span>קראתי ואני מסכים לתקנון</span>
        </label>
      </div>
      
      {message && <p>{message}</p>}
    </form>
  );
};

export default FormFinal;
