import React, { useState } from "react";
import { Link } from "react-router-dom";
// import {
//   Accordion,
//   AccordionItem,
//   AccordionItemHeading,
//   AccordionItemButton,
//   AccordionItemPanel,
// } from "react-accessible-accordion";
// import "react-accessible-accordion/dist/fancy-example.css";
import "../assets/css/FaqPage.css";
import GamePage from "./GamePage";
import TagManager from "react-gtm-module";

export const FaqPage = (props) => {
  return (
    <div className="ac-faq-page">
      <div className="ac-container">
        <span className="ac-faq-title">שאלותשובות</span>
        {/* <Accordion>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <span className="ac-question">
                  {" "}
                  מה התאריכים של הפעילות ביקום הזה?
                </span>
                <span className="ac-arrow">
                  <span></span>
                </span>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>בין התאריכים: 10.11.22 בשעה 10:00 עד 24.11.22 בשעה 23:59.</p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <span className="ac-question">
                  {" "}
                  מאיזה גיל אפשר להשתתף במשחק?
                </span>
                <span className="ac-arrow">
                  <span></span>
                </span>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>מגיל 18. בכפוף לאישור אפוטרופוס ניתן להשתתף מגיל 16.</p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <span className="ac-question"> איך מוסיפים חשבונית?</span>
                <span className="ac-arrow">
                  <span></span>
                </span>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                אחרי שמסיימים לשחק ומזינים פרטים, מופיעות הנחיות להעלאת
                החשבונית.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <span className="ac-question">
                  {" "}
                  מה אני עושה אם אין לי חשבונית כרגע?
                </span>
                <span className="ac-arrow">
                  <span></span>
                </span>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                נוכל לשלוח לך SMS עם קישור מיוחד, שמאפשר להתחבר אליו מתי שרוצים
                ולהוסיף באמצעותו חשבונית עד גמר הפעילות.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <span className="ac-question">מה הפרסים?</span>
                <span className="ac-arrow">
                  <span></span>
                </span>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                מיני קופר לזוכה במקום הראשון (מקסימום התרגשות!) בנוסף, בכל יום
                נחלק 5 כרטיסים זוגיים לקולנוע ל- 5 המקומות הראשונים באותו היום.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <span className="ac-question"> אפשר לזכות בכמה פרסים?</span>
                <span className="ac-arrow">
                  <span></span>
                </span>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                אפשר לזכות בפרס היומי פעם אחת ואולי גם לזכות במיני קופר, במידה
                והתוצאה שלך תהיה הטובה ביותר בכל תקופת הפעילות. בהצלחה!
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <span className="ac-question">
                  כמה פעמים אפשר לשחק באוראו במולטיוורס?
                </span>
                <span className="ac-arrow">
                  <span></span>
                </span>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                אפשר לשחק ללא הגבלה. כדי לזכות יש לצרף חשבונית בשווי 25 ₪ על
                מגוון עוגיות אוראו, עבור כל טופס השתתפות.{" "}
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <span className="ac-question">איך אפשר ליצור איתכם קשר?</span>
                <span className="ac-arrow">
                  <span></span>
                </span>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                אנו מזמינים אותך לשלוח לנו הודעה פרטית בעמוד{" "}
                <a
                  href="https://www.instagram.com/oreo_il/"
                  target="_blank"
                  className="ac-insta"
                  rel="noopener"
                >
                  האינסטגרם של אוראו {`>>`}
                </a>
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion> */}
        <Link
          to="/"
          onClick={() => {
            // window.location.replace("https://testmilkaandoreo.activated.co.il/");

            TagManager.dataLayer({ dataLayer: { event: "lets_start" } });
          }}
          className="ac-btn ac-start-game-btn"
        >
          <div className="ac-inner-btn">
            <span>למשחק</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21.167"
              height="21.168"
              viewBox="0 0 21.167 21.168"
            >
              <g data-name="Group 32" transform="translate(0 10.584)">
                <path
                  fill="#0057c8"
                  d="M0 0l21.167 10.584v-21.168z"
                  data-name="Path 17"
                ></path>
              </g>
            </svg>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default FaqPage;
