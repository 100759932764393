export const NotFoundData = [
  {
    title: "",
    desc: `זה לא השחקן`,
  },
  {
    title: "",
    desc: `זה לא השחקן`,
  },
  {
    title: "",
    desc: `זה לא השחקן`,
  },
  {
    title: "",
     desc: `זה לא השחקן`,
  },
];