import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import facebookIcon from "../assets/img/shareFBsmall.svg";
import { FacebookShareButton } from "react-share";
import TagManager from "react-gtm-module";
import pdf from "../assets/takanon.pdf";

export const Menu = (props) => {
  const [menuOpen, setmenuOpen] = useState(false);
  const [shareOpen, setshareOpen] = useState(false);
  const location = useLocation();

  // const cssHeaderGame = props?.loader ? "ac_hide_header" : "";
  const cssHeaderGame = "";
  const cssHeaderGame2 =
    !props?.isNotYalla && location?.pathname === "/"
      ? "ac_imp_header_color"
      : "";
  const cssClassOpenMenu = menuOpen ? "ac-opened" : "ac-closed";
  const cssClassShareMenu = shareOpen ? "ac-opened" : "ac-closed";

  let menuRef = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", (event) => {
      if (!menuRef.current.contains(event.target)) {
        setmenuOpen(false);
        setshareOpen(false);
      }
    });
  });

  return (
    <div>
      <header ref={menuRef} className={`${cssHeaderGame} ${cssHeaderGame2}`}>
        <div className="ac-container">
        <Link
            to="/sharePage"
            id="ac-share-page"
            onClick={() => {
              setmenuOpen(false);
              TagManager.dataLayer({ dataLayer: { event: "true_friend" } });
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlSpace="preserve"
              width={800}
              height={800}
              fill="#fff"
              viewBox="0 0 493.525 493.525"
              {...props}
            >
              <path d="M430.557 79.556H218.44c21.622 12.688 40.255 29.729 54.859 49.906h157.258c7.196 0 13.063 5.863 13.063 13.06v238.662c0 7.199-5.866 13.064-13.063 13.064H191.894c-7.198 0-13.062-5.865-13.062-13.064V222.173c-6.027-3.1-12.33-5.715-18.845-7.732-3.818 11.764-12.105 21.787-23.508 27.781-2.39 1.252-4.987 2.014-7.554 2.844v136.119c0 34.717 28.25 62.971 62.968 62.971h238.663c34.718 0 62.969-28.254 62.969-62.971V142.522c0-34.716-28.25-62.966-62.968-62.966z" />
              <path d="M129.037 175.989c51.419 1.234 96.388 28.283 122.25 68.865a12.676 12.676 0 0 0 10.657 5.848c1.152 0 2.322-.162 3.46-.486 5.377-1.545 9.114-6.418 9.179-12.006v-1.51c0-81.148-64.853-147.023-145.527-148.957V64.155a14.798 14.798 0 0 0-7.879-13.078 14.781 14.781 0 0 0-6.889-1.707c-2.94 0-5.848.88-8.35 2.584L5.751 120.526a13.219 13.219 0 0 0-.064 21.77l100.17 69.451a14.701 14.701 0 0 0 8.414 2.631 14.82 14.82 0 0 0 6.857-1.676 14.8 14.8 0 0 0 7.909-13.092v-23.621z" />
            </svg>
            
          </Link>
          <nav className={cssClassOpenMenu}>
            <div className="ac-wrapper-close">
              <button
                onClick={() => {
                  setmenuOpen(false);
                }}
              >
                <span>+</span>
              </button>
            </div>
            <ul>
              <li
                onClick={() => {
                  window.location.replace("https://testmilkaandoreo.activated.co.il/");
                  setmenuOpen(false);
                  TagManager.dataLayer({
                    dataLayer: { event: "menu_multiverse" },
                  });
                }}
              >
                <Link to="/">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25.269"
                    height="28.985"
                    viewBox="0 0 25.269 28.985"
                  >
                    <g
                      data-name="Group 39"
                      transform="translate(18.209 18.311)"
                    >
                      <path
                        fill="#fff"
                        d="M0 0a.826.826 0 01.826.826.825.825 0 01-.826.825h-11.577A.825.825 0 01-12.4.826.826.826 0 01-11.577 0zm5.412-10.565q0-.446-.024-.868c-.149-2.574-.959-4.457-2.4-5.041-1.158-.47-2.646-.042-4.207 1.1a17.6 17.6 0 00-4.254 4.811zm-8.744 5.172a.825.825 0 01-.826-.825.826.826 0 01.826-.826h6.2a.826.826 0 01.826.826.825.825 0 01-.826.825zM-8.359-1.87a.827.827 0 01-.826-.825.827.827 0 01.826-.826h2.587a.826.826 0 01.826.826.826.826 0 01-.826.825zm-5.086-3.523a.826.826 0 01-.826-.825.827.827 0 01.826-.826h7.264a.827.827 0 01.827.826.826.826 0 01-.827.825zm-3.938 7.044a.825.825 0 01-.826-.825.826.826 0 01.826-.826h2.756a.825.825 0 01.827.826.825.825 0 01-.825.825zM-7.4-10.565A20.158 20.158 0 01-2.191-16.7c2.032-1.484 4.076-2 5.8-1.3 2.081.846 3.238 3.26 3.423 6.48A25.37 25.37 0 014.989-.923 25.364 25.364 0 01-.939 8.1c-2.376 2.179-4.886 3.1-6.965 2.26a4.894 4.894 0 01-2.541-2.6 11.141 11.141 0 01-.9-4.414.822.822 0 01.8-.841.822.822 0 01.842.8 9.531 9.531 0 00.748 3.756 3.3 3.3 0 001.67 1.763c1.439.585 3.335-.2 5.236-1.943A23.7 23.7 0 003.46-1.542l.131-.328h-6.343a.826.826 0 01-.825-.825.826.826 0 01.825-.826H4.1a.731.731 0 01.081 0 24.924 24.924 0 001.15-5.4H-10.51a.826.826 0 01-.826-.826.825.825 0 01.826-.825z"
                        data-name="Path 21"
                      ></path>
                    </g>
                  </svg>
                  <span>אוראו במולטיוורס</span>
                </Link>
              </li>
              <li
                onClick={() => {
                  setmenuOpen(false);
                  TagManager.dataLayer({ dataLayer: { event: "menu_prizes" } });
                }}
              >
                <Link to="/goalsPage">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20.831"
                    height="25.914"
                    viewBox="0 0 20.831 25.914"
                  >
                    <g data-name="Group 40" transform="translate(20.371 1.661)">
                      <path
                        fill="#fff"
                        d="M0 0h-2.945q.022-.6.023-1.2a.46.46 0 00-.46-.46h-13.147a.46.46 0 00-.46.46q0 .6.024 1.2h-2.946a.46.46 0 00-.46.46 11.3 11.3 0 001.7 6.074 6 6 0 003.878 2.876 7.79 7.79 0 003.019 3.45c.486.313.528 1.157.51 1.637a8.016 8.016 0 01-.6 2.571h-2.927a.46.46 0 00-.46.46v3.7h-1.311a.46.46 0 00-.46.46v2.107a.46.46 0 00.46.46h13.146a.46.46 0 00.46-.46v-2.109a.46.46 0 00-.46-.46h-1.243v-3.7a.46.46 0 00-.46-.46h-2.924c-.787-1.911-.828-3.769-.068-4.222A7.828 7.828 0 00-5.118 9.41a6 6 0 003.881-2.876A11.3 11.3 0 00.46.46.46.46 0 000 0m-3.876 23.333H-16.1v-1.188h12.224zM-19.442.92h2.526a23.848 23.848 0 001.624 7.408c-2.046-.777-4-3.637-4.15-7.408m13.863 20.3h-8.753v-3.232h8.753zm-3.992-6.7a8.525 8.525 0 00.542 2.547h-1.852a8.693 8.693 0 00.536-2.535 4.221 4.221 0 00-.115-1.232 3.694 3.694 0 00.505.035 3.676 3.676 0 00.5-.035 4.17 4.17 0 00-.119 1.223m1.874-3.096a3.358 3.358 0 01-2.25.987 3.359 3.359 0 01-2.251-.987 8.966 8.966 0 01-1.981-2.87 23.372 23.372 0 01-1.879-9.3h12.216a23.372 23.372 0 01-1.879 9.3 8.95 8.95 0 01-1.976 2.87m3.086-3.1A23.848 23.848 0 00-2.995.92h2.526c-.148 3.771-2.1 6.631-4.15 7.408"
                        data-name="Path 22"
                      ></path>
                    </g>
                  </svg>
                  <span>פרסים</span>
                </Link>
              </li>
              <li
                onClick={() => {
                  setmenuOpen(false);
                  TagManager.dataLayer({ dataLayer: { event: "menu_shares" } });
                }}
              >
                <Link to="/sharePage">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16.637"
                    height="20.434"
                    viewBox="0 0 16.637 20.434"
                  >
                    <g
                      data-name="Group 42"
                      transform="translate(13.059 13.889)"
                    >
                      <path
                        fill="none"
                        stroke="#fff"
                        strokeWidth="1.22"
                        d="M0 0a2.954 2.954 0 00-1.979.757l-4.747-3.325a2.957 2.957 0 00.213-1.1 2.957 2.957 0 00-.213-1.1L-1.979-8.1A2.958 2.958 0 000-7.344a2.967 2.967 0 002.968-2.967A2.968 2.968 0 000-13.279a2.968 2.968 0 00-2.968 2.968 2.961 2.961 0 00.213 1.1L-7.5-5.883a2.957 2.957 0 00-1.981-.757 2.968 2.968 0 00-2.968 2.968A2.968 2.968 0 00-9.481-.7 2.952 2.952 0 00-7.5-1.461l4.747 3.325a2.953 2.953 0 00-.213 1.1A2.967 2.967 0 000 5.935a2.967 2.967 0 002.968-2.967A2.968 2.968 0 000 0z"
                        data-name="Path 24"
                      ></path>
                    </g>
                  </svg>
                  <span>שיתופים</span>
                </Link>
              </li>
              <li
                onClick={() => {
                  setmenuOpen(false);
                  TagManager.dataLayer({
                    dataLayer: { event: "menu_questions" },
                  });
                }}
              >
                <Link to="/faqPage">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22.216"
                    height="22.628"
                    viewBox="0 0 22.216 22.628"
                  >
                    <g
                      data-name="Group 473"
                      transform="translate(-378.731 -287.619)"
                    >
                      <g
                        data-name="Group 47"
                        transform="translate(389.432 294.934)"
                      >
                        <path
                          fill="#fff"
                          d="M0 0a1.621 1.621 0 001.168-.471 1.636 1.636 0 00.482-1.187 1.633 1.633 0 00-.487-1.181A1.612 1.612 0 000-3.315a1.615 1.615 0 00-1.164.476 1.632 1.632 0 00-.486 1.181 1.636 1.636 0 00.482 1.187A1.621 1.621 0 000 0"
                          data-name="Path 29"
                        ></path>
                      </g>
                      <g
                        data-name="Group 48"
                        transform="translate(392.106 303.55)"
                      >
                        <path
                          fill="#fff"
                          d="M0 0a1.441 1.441 0 01-.593-.194l-.013-.013a.218.218 0 01-.066-.12 3.611 3.611 0 01-.053-.786l-.009-.731v-5.829a.253.253 0 00-.256-.256l-3.72.042a.255.255 0 00-.248.238l-.084 1.207a.255.255 0 00.234.274l.477.032a1.056 1.056 0 01.354.063.3.3 0 01.09.11l.01.015a.769.769 0 01.089.318 12.863 12.863 0 01.042 1.313v2.408c0 .544-.013.959-.039 1.235a1.386 1.386 0 01-.081.423.384.384 0 01-.073.119 1.878 1.878 0 01-.549.125l-.463.063a.251.251 0 00-.215.25v1.225a.25.25 0 00.247.252h5.31a.25.25 0 00.247-.252V.33a.25.25 0 00-.2-.247z"
                          data-name="Path 30"
                        ></path>
                      </g>
                      <g
                        data-name="Group 49"
                        transform="translate(389.839 287.619)"
                      >
                        <path
                          fill="#fff"
                          d="M0 0a11.225 11.225 0 00-11.108 11.314A11.225 11.225 0 000 22.628a11.225 11.225 0 0011.108-11.314A11.225 11.225 0 000 0m9.542 11.314A9.642 9.642 0 010 21.033a9.642 9.642 0 01-9.542-9.719A9.642 9.642 0 010 1.6a9.642 9.642 0 019.542 9.719"
                          data-name="Path 31"
                        ></path>
                      </g>
                    </g>
                  </svg>
                  <span>שאלותשובות</span>
                </Link>
              </li>
              <li
                onClick={() => {
                  setmenuOpen(false);
                  TagManager.dataLayer({
                    dataLayer: { event: "menu_takanon" },
                  });
                }}
              >
                <Link to="/takanonPage">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16.637"
                    height="24"
                    viewBox="0 0 16.637 24"
                  >
                    <g
                      data-name="Group 474"
                      transform="translate(-380.257 -344.166)"
                    >
                      <g
                        data-name="Group 51"
                        transform="translate(393.767 348.606)"
                      >
                        <path
                          fill="none"
                          stroke="#fff"
                          strokeWidth="0.5"
                          d="M0 0h-10"
                          data-name="Path 33"
                        ></path>
                      </g>
                      <g
                        data-name="Group 52"
                        transform="translate(393.767 356.219)"
                      >
                        <path
                          fill="none"
                          stroke="#fff"
                          strokeWidth="0.5"
                          d="M0 0h-10"
                          data-name="Path 34"
                        ></path>
                      </g>
                      <g
                        data-name="Group 53"
                        transform="translate(393.767 352.412)"
                      >
                        <path
                          fill="none"
                          stroke="#fff"
                          strokeWidth="0.5"
                          d="M0 0h-10"
                          data-name="Path 35"
                        ></path>
                      </g>
                      <g
                        data-name="Group 54"
                        transform="translate(393.767 360.026)"
                      >
                        <path
                          fill="none"
                          stroke="#fff"
                          strokeWidth="0.5"
                          d="M0 0h-10"
                          data-name="Path 36"
                        ></path>
                      </g>
                      <g
                        data-name="Group 55"
                        transform="translate(393.767 363.833)"
                      >
                        <path
                          fill="none"
                          stroke="#fff"
                          strokeWidth="0.5"
                          d="M0 0h-10"
                          data-name="Path 37"
                        ></path>
                      </g>
                      <path
                        fill="none"
                        stroke="#fff"
                        strokeWidth="1"
                        d="M380.757 367.666h15.637v-23h-15.637z"
                        data-name="Path 38"
                      ></path>
                    </g>
                  </svg>
                  <span>תקנון</span>
                </Link>
              </li>
            </ul>
          </nav>
          <button
            id="ac-icon-nav"
            onClick={() => {
              if (!menuOpen) {
                setmenuOpen(true);
              } else {
                setmenuOpen(false);
              }

              if (shareOpen) {
                setshareOpen(false);
              }
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="39.065"
              height="25.785"
              viewBox="0 0 39.065 25.785"
            >
              <defs>
                <clipPath id="a">
                  <path
                    d="M0-15.267H39.065V-41.052H0Z"
                    transform="translate(0 41.052)"
                    fill="none"
                  />
                </clipPath>
              </defs>
              <g clipPath="url(#a)">
                <g transform="translate(0)">
                  <path
                    d="M33.367.9H-1.3A2.2,2.2,0,0,1-3.5-1.3,2.2,2.2,0,0,1-1.3-3.5H33.367a2.2,2.2,0,0,1,2.2,2.2A2.2,2.2,0,0,1,33.367.9Z"
                    transform="translate(3.5 3.5)"
                    fill="#fff"
                  />
                </g>
                <g transform="translate(0 10.694)">
                  <path
                    d="M33.367.9H-1.3A2.2,2.2,0,0,1-3.5-1.3,2.2,2.2,0,0,1-1.3-3.5H33.367a2.2,2.2,0,0,1,2.2,2.2A2.2,2.2,0,0,1,33.367.9Z"
                    transform="translate(3.5 3.5)"
                    fill="#fff"
                  />
                </g>
                <g transform="translate(0 21.388)">
                  <path
                    d="M33.367.9H-1.3A2.2,2.2,0,0,1-3.5-1.3,2.2,2.2,0,0,1-1.3-3.5H33.367a2.2,2.2,0,0,1,2.2,2.2A2.2,2.2,0,0,1,33.367.9Z"
                    transform="translate(3.5 3.5)"
                    fill="#fff"
                  />
                </g>
              </g>
            </svg>
          </button>
          {/* <button
            id="ac-share-page"
            onClick={() => {
              if (!shareOpen) {
                setshareOpen(true);
              } else {
                setshareOpen(false);
              }
              if (menuOpen) {
                setmenuOpen(false);
              }
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21.663"
              height="27.001"
              viewBox="0 0 21.663 27.001"
            >
              <g data-name="Group 22" transform="translate(17.493 18.66)">
                <path
                  fill="#fff"
                  d="M0 0a4.153 4.153 0 00-2.781 1.063l-6.671-4.671a4.148 4.148 0 00.3-1.552 4.147 4.147 0 00-.3-1.551l6.671-4.672A4.153 4.153 0 000-10.32a4.17 4.17 0 004.17-4.17A4.17 4.17 0 000-18.66a4.17 4.17 0 00-4.17 4.17 4.168 4.168 0 00.3 1.552l-6.671 4.671a4.153 4.153 0 00-2.782-1.063 4.17 4.17 0 00-4.17 4.17 4.171 4.171 0 004.17 4.171 4.154 4.154 0 002.781-1.064l6.671 4.672a4.166 4.166 0 00-.3 1.551A4.17 4.17 0 000 8.341 4.17 4.17 0 004.17 4.17 4.17 4.17 0 000 0"
                  data-name="Path 9"
                ></path>
              </g>
            </svg>
            <span>share</span>
          </button> */}
         

          <div className={`ac-share-menu-popup ` + cssClassShareMenu}>
            <div className="ac-related-arrow">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="sort-up"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                class="svg-inline--fa fa-sort-up fa-w-10 fa-2x"
              >
                <path
                  fill="currentColor"
                  d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z"
                  class=""
                ></path>
              </svg>
            </div>

            <div className="ac-container">
              <div className="ac-top">
                <p>שתף</p>
              </div>
              <div className="ac-bottom">
                <ul>
                  <li>
                    <Link
                      to="/SharePage"
                      onClick={() => {
                        TagManager.dataLayer({
                          dataLayer: { event: "whatsapp_share_menu" },
                        });
                      }}
                    >
                      <img
                        src="/static/media/shareWAsmall.74a2d067.svg"
                        alt="whatsapp"
                        class="ac-img-responive"
                      />
                    </Link>
                  </li>
                  <li>
                    <FacebookShareButton
                      url={"https://testmilkaandoreo.activated.co.il/"}
                      onClick={() => {
                        TagManager.dataLayer({
                          dataLayer: { event: "facebook_share_submit" },
                        });
                      }}
                    >
                      <img
                        src={facebookIcon}
                        alt="facebook"
                        className="ac-img-responive"
                      />
                    </FacebookShareButton>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
    </div>
  );
};
